const removeCookie = (cookieName, cookiePath) => {
  if (cookieName) {
    const newCookieString = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
    if (cookiePath) {
      document.cookie = `${newCookieString} path=${cookiePath};`;
    }
    document.cookie = newCookieString;
  }
};

export {removeCookie};
