/* eslint-disable default-case */
/* eslint-disable no-empty */
/* eslint-disable no-undef */

import setDyUserActiveConsent from './setDyUserActiveConsent';
import setDyFirstPartyCookies from './setDyFirstPartyCookies';

/**
 * @function
 * @description This executes in each redux action triggered.
 * @param store Redux store
 * @param next Next redux middleware
 * @param action Redux action
 */
const dynamicYieldMiddleware = (/*store*/) => next => action => {
  // This actions executes in every page change
  setDyUserActiveConsent(action);
  setDyFirstPartyCookies(action, false);

  // Execute next action
  return next(action);
};

export default dynamicYieldMiddleware;
