import axios from 'axios';

export const getRequest = (url, config) => {
  return axios.get(url, config);
};

export const postRequest = (url, payload, config) => {
  return axios.post(url, payload, config);
};

export const putRequest = (url, payload, config) => {
  return axios.put(url, payload, config);
};

export const patchRequest = (url, payload, config) => {
  return axios.patch(url, payload, config);
};

export const deleteRequest = (url, config) => {
  return axios.delete(url, config);
};
