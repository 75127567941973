/* eslint-disable unicorn/filename-case */
import {getCookieValue} from '@oracle-cx-commerce/utils/node';

import {postRequest} from '@banco-de-chile/travel/src/utils/restClient';

/**
 * @function
 * @description Call to the endpoint '/ccstorex/custom/v1/dynamic-yield/first-party-to-third-party'
 * to set the first party cookies to resolve the third party cookies
 * @param {string} action Redux action name.
 * @param {boolean} calledFromAcceptanceModal Flang indicating if the call comes from the acceptance modal.
 */
const setDyFirstPartyCookies = (action, calledFromAcceptanceModal = false) => {
  const localStorageCookiesConsentAccepted = localStorage.getItem('cookies-consent-accepted') === 'true';
  const dyIdServerCookieValue = getCookieValue(document.cookie, '_dyid_server');
  if (
    calledFromAcceptanceModal ||
    ((action.type === 'initComplete' || action.type === 'getApplicationPageResolved') &&
      localStorageCookiesConsentAccepted &&
      !dyIdServerCookieValue)
  ) {
    postRequest(
      '/ccstorex/custom/v1/dynamic-yield/first-party-to-third-party',
      {},
      {
        'Content-Type': 'application/json'
      }
    );
  }
};

export default setDyFirstPartyCookies;
